import { Component, OnInit, Input, NgZone, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { Utilities } from '../../../services/core/utilities';
import { SpotService } from '../../../services/media/spot.service';
import { BasePopupComponent } from '../../popups/basepopup/basepopup.component';

@Component({
  selector: 'jw-player',
  templateUrl: './jwplayer.component.html',
  styleUrls: ['./jwplayer.component.css']
})
export class JwPlayerComponent extends BasePopupComponent implements OnInit {
  @ViewChild('playerContainer', { static: false }) playerContainer!: ElementRef;
  @Input('videoSource') videoSource: string;
  @Input('thumbnail') thumbnail: string;
  @Input('title') title: string;
  @Input('format') format: string;

  public proxyUrl: string = null;
  public overyLayHeightQ: number = 0;
  public is169OverlayRequired: boolean = false;
  public is430OverlayRequired: boolean = false;
  public isOver169Overlay: boolean = false;
  public isOver43Overlay: boolean = false;
  public playerWidthClass: string = '';
  private player: any;
  public isPopup: boolean = false;
  public smpteTimecode: string = '00:00:00:00';

  constructor(private ngZone: NgZone, public bsModalRef: BsModalRef, public util: Utilities, public spotService: SpotService) {
    super();
    
    window.onresize = (e) => {
      ngZone.run(() => {
        this.isOver169Overlay = false;
        this.isOver43Overlay = false;
      });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.is169OverlayRequired = this.util.is169OverlayRequired(this.format);
    this.is430OverlayRequired = this.util.is430verlayRequired(this.format);
    this.getProxyUrl();
  }

  ngAfterViewInit() {
    this.initializeJWPlayer();
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.remove();
    }
  }

  private initializeJWPlayer() {
    const playerOptions = {
      file: this.proxyUrl || this.videoSource,
      title: this.title,
      image: this.thumbnail,
    };

    this.player = jwplayer(this.playerContainer.nativeElement).setup(playerOptions);

    this.player.on('ready', () => {
      this.onVideoPlayerLoaded();
    });

    this.player.on('time', (event) => {
      this.smpteTimecode = this.formatSMPTETime(event.position);
    });
  }

  private formatSMPTETime(seconds: number): string {
    const fps = 30;
    const totalFrames = Math.floor(seconds * fps);
    const hours = Math.floor(totalFrames / (fps * 60 * 60));
    const minutes = Math.floor((totalFrames % (fps * 60 * 60)) / (fps * 60));
    const secondsPart = Math.floor((totalFrames % (fps * 60)) / fps);
    const frames = totalFrames % fps;

    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secondsPart)}:${this.pad(frames)}`;
  }

  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  private onVideoPlayerLoaded() {
    this.updatePlayerWidthClass();
  }

  private updatePlayerWidthClass() {
    const width = this.playerContainer.nativeElement.offsetWidth;
    this.playerWidthClass = this.getPlayerWidthClass(width);
  }

  getPlayerWidthClass(width: number): string {
    if (width >= 1280) {
      return 'col-md-12';
    } else if (width >= 1008 && width < 1280) {
      return 'col-md-8';
    } else {
      return 'col-md-8';
    }
  }

  private getProxyUrl() {
    this.spotService.getProxyUrl({ objectKey: this.videoSource }).subscribe((res: any) => {
      if (res.isSuccess) {
        this.proxyUrl = res.result;
        if (this.player) {
          this.player.load({ file: this.proxyUrl });
        }
      } else {
        this.util.handleIsNotSuccess(res.errors);
        this.closePopup();
      }
    },
    error => {
      this.util.handleError(error);
      this.closePopup();
    });
  }

  public onContextMenu(event: MouseEvent) {
    event.preventDefault(); 
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public toggleOverlayQ(overlayType: string) {
    if (overlayType === '169') {
      this.isOver43Overlay = false;
      this.isOver169Overlay = !this.isOver169Overlay;
    } else {
      this.isOver169Overlay = false;
      this.isOver43Overlay = !this.isOver43Overlay;
    }

    this.overyLayHeightQ = this.playerContainer.nativeElement.offsetHeight;
  }

  public onPlayerMouseOver(e) {
    this.isOver169Overlay = false;
    this.isOver43Overlay = false;
  }
}