import { Component, Inject, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Spot } from '../../../models/spot/spot.model';
import { AuthService } from "../../../services/auth/auth.service";
import { Observable } from "rxjs";
import { DxDataGridComponent } from "devextreme-angular";
import { DatGridService } from "../../../services/media/datagrid.service";
import { Constants } from "../../../configurations/constants";
import { SubService } from "../../../services/media/sub.service";
import { MediaService } from "../../../services/media/media.service";
import { UserTimeZone } from '../../../pipes/usertimezone.pipe';
import { Utilities } from "../../../services/core/utilities";
import { Router } from '@angular/router';
import { mediaDetailSource } from "../../../configurations/enums/enums";

import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddToPlayListComponent } from "../../popups/addtoplaylist/addtoplaylist.component";
import { PlayList } from '../../../models/playlist/playlist.model';
import { PlayListFile } from '../../../models/playlist/playlist-file.model';

import * as moment from 'moment';
import { ShareMediaPopupComponent } from "../../popups/sharemediapopup/sharemediapopup.component";
import { RecentlyViewed } from "../../../models/spot/recentlyviewed.model";
import { JwPlayerComponent } from '../../videoplayer/jwplayer/jwplayer.component';
import { SpotService } from "../../../services/media/spot.service";
import { RouteConstants } from "../../../configurations/route-constants";
import { GlobalService } from '../../../services/core/global.service';

@Component({
  selector: 'recently-viewed',
  templateUrl: './recentlyviewed.component.html',
  styleUrls: ['./recentlyviewed.component.css'],
})

export class RecentlyViewedComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @Input('recentlyViewedList') recentlyViewedList: RecentlyViewed[];

  public recentlyViewedSpots: RecentlyViewed[] = [];
  public selectAllRecentlyViewed: boolean[] = [];
  public modalRef: BsModalRef;
  public selectedMediaFiles: Array<number> = [];

  constructor(private datGridService: DatGridService,
    private util: Utilities,
    private mediaService: MediaService,
    private router: Router,
    private alertService: AlertService,
    private modalService: BsModalService,
    private subService: SubService,
    private spotService: SpotService,
    private gs: GlobalService) {

    this.subService.refreshPlayListRecentlyViewedMethodCalled.subscribe(
      () => {
        this.deSelectRows();
      }
    );

    this.subService.deselectRecentlyViewedRowsCalled.subscribe(
      () => {
        this.deSelectRows();
      }
    );

    this.subService.refreshRecentlyViewedBacktoMediaMethodCalled.subscribe(
      () => {
        this.refreshRecentlyViewedSpots();
      }
    );
  }

  ngOnInit() {
    for (let s of this.recentlyViewedList) {
      s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), (s.status.toLowerCase() == 'awaiting media'), (s.status.toLowerCase() == 'creating media'));

      s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);

      s.formatSources = this.util.getAllIconSources(s.format, s.audioFormat, s.spotTrac, s.veilEncode, s.subtitles, s.centerCutProtected,
        s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc, s.sap, s.vchip, s.letterBox, s.surround, s.mono);
    }

    this.recentlyViewedSpots = this.recentlyViewedList;
  }

  public refreshRecentlyViewedSpots() {

    this.alertService.ShowLoader(true);

    this.spotService.getRecentlyViewedSpots().subscribe((res: any) => {
      if (res.isSuccess == true) {
        var spots = res.result as RecentlyViewed[];

        for (let s of spots) {
          //s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl);
          s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), false, (s.status.toLowerCase() == 'creating media'));

          s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);
        }

        this.recentlyViewedSpots = spots as RecentlyViewed[];

        this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.recentlyViewedSpots = [];
      });
  }

  public atleastOneRowSelected() {
    var dataGrid = this.dataGrid.instance;
    return this.datGridService.atleastOneRowSelected(dataGrid);

  }

  public exportSpots() {
    if (this.dataGrid.instance.getSelectedRowKeys().length == 0)
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
    else {
      this.dataGrid.instance.exportToExcel(true);
    }
  }

  public shareSpots() {
    if (this.dataGrid.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }

    if (this.dataGrid.instance.getSelectedRowKeys().length > 0 && this.checkIfSelectedSpotsGotVideoFiles() === false) {
      this.alertService.showMessage("ERROR", Constants.noVideoFilesForSelectedSpots, MessageSeverity.error);
      return;
    }

    var initialState = {
      spotFileGuids: this.getSelectedSpotsAsArray(),
      albumName: null,
      source: "recentlyviewed"
    };

    this.modalRef = this.modalService.show(ShareMediaPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public deSelectRows() {
    this.dataGrid.instance.deselectRows(this.dataGrid.instance.getSelectedRowKeys());
  }

  public getSelectedSpotsAsPlayListFiles(): PlayListFile[] {
    let selectedSpotFiles: string[] = [];
    let playListFiles: PlayListFile[] = [];
    var grid = this.dataGrid.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedSpotFiles.push(grid.getSelectedRowsData()[i].spotFileGuid);
    }

    for (var i = 1; i <= selectedSpotFiles.length; i++)
      playListFiles.push({ sequence: i, spotFileGuid: selectedSpotFiles[i - 1], playListFileGuid: null, proxyUrl: "", thumbnailUrl: null, title: "",displayTitle:"",adId:"", playListGuid: null, format: null });

    return playListFiles;
  }

  public getSelectedSpotsAsArray(): string[] {
    let selectedSpotFiles: string[] = [];
    var grid = this.dataGrid.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedSpotFiles.push(grid.getSelectedRowsData()[i].spotFileGuid);
    }

    return selectedSpotFiles;
  }

  getSelectedSpotsShareTitle(): string {
    var grid = this.dataGrid.instance;
    return grid.getSelectedRowsData()[0].title;
  }

  checkIfSelectedSpotsGotVideoFiles(): boolean {
    var grid = this.dataGrid.instance;
    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      if (grid.getSelectedRowsData()[i].isPlayable == false)
        return false;
    }
    return true;
  }

  public showChoosePlayListPopup() {

    if (this.dataGrid.instance.getSelectedRowKeys().length == 0 && this.selectedMediaFiles.length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);

      return;
    }

    if (this.dataGrid.instance.getSelectedRowKeys().length > 0 && this.checkIfSelectedSpotsGotVideoFiles() === false) {
      this.alertService.showMessage("ERROR", Constants.noVideoFilesForSelectedSpots, MessageSeverity.error);

      return;
    }

    var initialState = {
      selectedPlayListFiles: this.getSelectedSpotsAsPlayListFiles(),
      isPopup: true,
      source: 'recentlyviewed'
    };

    this.modalRef = this.modalService.show(AddToPlayListComponent, this.util.getModalComponentOptions(initialState, false, true, false));
  }

  public showDetail(id) {
    if (id == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openSpotFile(id);

    //this.router.navigate([RouteConstants.mediaDetailRoute, id], { queryParams: { source: mediaDetailSource[mediaDetailSource.RecentlyViewed] } });
  }

  public quickPlay(proxy, title, thumbnail, format) {

    if (proxy == null || proxy == '')
      return;

    var initialState = {
      videoSource: proxy,
      title: title,
      thumbnail: thumbnail,
      format: format
    };

    this.modalRef = this.modalService.show(JwPlayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }
}
