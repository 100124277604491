<div class="modal-header">
    <button
        type="button"
        class="modal-close"
        (click)="closePopup()"
    ><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title" title="{{modalTitle}}">{{modalTitle | truncateByLetters}}</h4>
</div>
<div class="modal-body">
    <div>
        <div class="player-container">
            <div
                *ngIf="proxyUrl"
                class="parent-video-overlay"
            >
                <div class="tylie-v-player video-overlay w-100">
                    <div class="tylie-v-player__body" style="z-index: 100">
                        <div class="safe-overlay-bg-4-3" [style.height]="overyLayHeight + 'px'"
                            *ngIf="isOver43Overlay == true" (mouseover)="onPlayerMouseOver($event)"></div>
                        <div class="safe-overlay-bg-16-9" [style.height]="overyLayHeight + 'px'" *ngIf="isOver169Overlay == true" (mouseover)="onPlayerMouseOver($event)"></div>
                        <jw-player #videoPlayer class="w-100"
                            [videoSource]="proxyUrl" 
                            [thumbnail]="thumbnail" 
                            [title]="modalTitle" 
                            [autostart]="true" 
                            [id]="'main-video'" 
                            [displayDownloadButton]="false"  
                            (onPlayerReady)="onVideoPlayerLoaded()"
                            (onContextMenu)="onContextMenu($event)">
                        </jw-player>
                    </div>
                </div>
            </div>
            <div class="tylie-v-player video-overlay" *ngIf="!proxyUrl">
                <img class="tylie-v-player__fallback-img" [src]="thumbnail" />
            </div>
        </div>
        <div>
          <Label>{{title}}</Label>
          <p>
            <span>#{{id}}</span>
          </p>
        </div>
    </div>
</div>
