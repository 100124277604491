import { Component, ViewChild, Input, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import CustomStore from 'devextreme/data/custom_store';
import { Router } from '@angular/router';
import { SortOptions } from '../../../../models/config/sortoptions.model';
import { Utilities } from '../../../../services/core/utilities';
import { AlertService } from '../../../../services/core/alert.service';
import { SpotService } from '../../../../services/media/spot.service';
import { GridParams } from '../../../../models/config/gridparams.model';
import { SpotFile } from '../../../../models/spot/spot-file.model';
import { ConfigService } from '../../../../services/core/config.service';
import { TranscodeMediaSearchRequest, TranscodeOrderConfig } from '../../../../models/order/transcodeorder-config.model';
import { FormControl, FormGroup } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { JwPlayerComponent } from '../../../videoplayer/jwplayer/jwplayer.component';
import { OrderService } from '../../../../services/order/order.service';
import { TranscodeOrderCopyUploadAssetsPopupComponent } from '../../../popups/transcodeordercopyuploadassetspopup/transcodeordercopyuploadassetspopup.component';
import { forEach } from 'core-js/fn/array';

@Component({
  selector: 'transcode-searchmedia-step',
  templateUrl: './transcode-searchmedia-step.component.html',
  styleUrls: ['./transcode-searchmedia-step.component.css']
})

export class TranscodeSearchMediaComponent implements OnInit {

  @Input() transcodeOrderConfig: TranscodeOrderConfig;
  @Input() selectedSpotFiles: Array<SpotFile>;
  @Input() isInternalRequest: boolean;
  @Input() originalWorkOrders: string;
  @Input() selectedClientId: number;
  @Input() selectedBrandId: number;
  @Input() requestOrigination: string;
  @Input() orderGroupSpotFileGuids: string[] = [];

  public clientDataSource: any = [];
  public brandDataSource: any = [];
  public productDataSource: any = [];
  public mediatypeDataSource: Array<any> = [];
  public formatDataSource: Array<any> = [];
  public resultMediaForTranscodingStore: any = [];
  public originalWorkOrdersDataSource: any = {};
  public loadClientCreativeButtonDisabled = true;
  public showAssetSearch = true;
  public isSaveSelection: boolean = false;
  public isSearchOrClearSearchClicked = false;

  private previousClientId: number = null;
  private previousBrandId: number = null;
  public clientId: number = null;
  public brandId: number = null;
  public productId: number = null;

  public quickSearchValue: string = '';
  public sortOptions: Array<SortOptions> = [{ desc: true, selector: 'created' }];
  public searchCriteriaObj: TranscodeMediaSearchRequest = <TranscodeMediaSearchRequest>{};
  public searchRequest: FormGroup;
  public nonBillableRequest: FormGroup;

  public modalRef: BsModalRef;

  @ViewChild('mediaForTranscodingGrid', { static: false }) mediaForTranscodingGrid: DxDataGridComponent;

  constructor(
    private util: Utilities,
    private router: Router,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public alertService: AlertService,
    private configService: ConfigService,
    private spotService: SpotService,
    private orderService: OrderService) {
  }

  ngOnInit() {

    this.formatDataSource = this.transcodeOrderConfig.formats;

    this.mediatypeDataSource = this.transcodeOrderConfig.mediaTypes;

    this.clientDataSource = this.transcodeOrderConfig.clients;

    this.searchRecentTranscodeOrders();

    this.brandDataSource = [];

    this.searchRequest = new FormGroup({
      adID: new FormControl(),
      title: new FormControl(),
      brand: new FormControl(),
      client: new FormControl(),
      product: new FormControl(),
      mediaType: new FormControl(),
      format: new FormControl(),
      quickSearch: new FormControl()
    });

    if (this.requestOrigination == 'Transcode') {
      if (this.transcodeOrderConfig.allowSpecialOptions == true) {

        this.nonBillableRequest = new FormGroup({
          isInternalRequest: new FormControl(this.isInternalRequest),
          originalWorkOrders: new FormControl(this.originalWorkOrders)
        });

        this.nonBillableRequest.controls['originalWorkOrders'].valueChanges.subscribe(val => {
          if (val != null && val != undefined && val.trim() != '') {
            this.nonBillableRequest.controls['isInternalRequest'].setValue(true);
          }
          else {
            this.nonBillableRequest.controls['isInternalRequest'].setValue(false);
          }
        });

        this.showAssetSearch = false;

        this.clientId = this.selectedClientId;

        if (this.selectedClientId != null && this.selectedClientId > 0) {
          this.showAssetSearch = true;
          this.onClientChangeConfirmed();
          this.onClickLoadCreative();
        }
      }

      if (this.transcodeOrderConfig.allowSpecialOptions == false && this.clientDataSource.length == 1) {
        this.searchRequest.controls['client'].setValue(this.clientDataSource[0].id);
        this.clientId = this.clientDataSource[0].id;
        this.loadClientCreativeButtonDisabled = false;
        this.onClientChangeConfirmed();

        this.refreshSearchResults(null, false);
      }
    }
    else if (this.requestOrigination == 'Order' || this.requestOrigination == 'OrderEdit') {
      this.searchRequest.controls['client'].setValue(this.selectedClientId);
      this.clientId = this.selectedClientId;

      var request = <TranscodeMediaSearchRequest>{};

      request.client = this.selectedClientId;
      request.brand = this.selectedBrandId;

      this.refreshSearchResults(request, false);
    }
  }

  private searchMedia() {

    var searchCriteriaObj = this.searchCriteriaObj;
    searchCriteriaObj.client = this.clientId;
    var util = this.util;
    var spotService = this.spotService;
    var alertService = this.alertService;

    this.resultMediaForTranscodingStore = new CustomStore({
      key: "spotFileGuid",
      load: function (loadOptions: any) {
        Promise.resolve(null).then(() => alertService.ShowLoader(true));
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'created','desc':true}]";

        searchCriteriaObj.gridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) };

        return spotService.searchSpotsForTranscodeOrder(searchCriteriaObj)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {

              var spotFiles = response.result.spotFiles as Array<SpotFile>;

              for (let s of spotFiles) {

                s.thumbnailUrl = util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), (s.status.toLowerCase() == 'awaiting media'), (s.status.toLowerCase() == 'creating media'));

                s.proxyUrl = util.getProxyUrl(s.proxyUrl);

                s.formatSources = util.getAllIconSources(s.format, s.audioFormat, s.spotTrac,
                  s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
                  s.sap, s.vchip, s.letterBox, s.surround, s.mono);

                s.formatClasses = util.getAllIconClasses(s.format, s.audioFormat, s.spotTrac,
                  s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo, s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc,
                  s.sap, s.vchip, s.letterBox, s.surround, s.mono);
              }

              var obj: any = {
                data: spotFiles,
                totalCount: response.result.totalCount
              };
              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            alertService.ShowLoader(false);
            util.handleError(error);
            throw 'Data Loading Error';
          });
      },
      onLoaded: function () {
        alertService.ShowLoader(false);
      }
    });
  }

  public searchRecentTranscodeOrders() {
    var orderService = this.orderService;
    var util = this.util;

    this.originalWorkOrdersDataSource.store = new CustomStore({
      key: "workOrder",
      load: function (loadOptions: any) {
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "";

        let gridParams: GridParams = { group: null, skip: 0, take: 0, sort: sortOptions, isExport: false };
        let request: any = { quickSearch: loadOptions.searchValue, searchParams: gridParams };

        return orderService.searchRecentTranscodeOrders(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {

              var searchResponse = response.result;
              var obj: any = {
                data: searchResponse.transcodeOrders,
                totalCount: searchResponse.totalCount
              };

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  public addOriginalWorkOrderToTextField(e) {
    console.log(e)
    if (e != null && e != undefined && e.selectedItem != null) {
      var originalWorkOrderVal = this.nonBillableRequest.controls['originalWorkOrders'].value;
      if (originalWorkOrderVal == null || originalWorkOrderVal == undefined || originalWorkOrderVal.trim() == '') {
        this.nonBillableRequest.controls['originalWorkOrders'].setValue(e.selectedItem.workOrder);
      }
      else {
        var originalWorkOrderVals: string[] = originalWorkOrderVal.split(",");

        let lowerCaseVals = [];

        originalWorkOrderVals.map(t => {
          lowerCaseVals.push(t.toLowerCase());
        });

        console.log(lowerCaseVals);

        if (lowerCaseVals.includes(e.selectedItem.workOrder.toLowerCase()) == false) {
          console.log(e.selectedItem.workOrder.toLowerCase())
          originalWorkOrderVal = originalWorkOrderVal + "," + e.selectedItem.workOrder;
          this.nonBillableRequest.controls['originalWorkOrders'].setValue(originalWorkOrderVal);
        }
      }
    }
  }

  public onClickLoadCreative() {
    this.showAssetSearch = true;
    this.searchRequest.controls['client'].setValue(this.clientId);
    this.searchRequest.controls['client'].updateValueAndValidity();

    if (this.transcodeOrderConfig != null && this.transcodeOrderConfig.clients != null) {
      var selectedClient = this.transcodeOrderConfig.clients.find(c => c.id == this.clientId);

      if (selectedClient != null && this.transcodeOrderConfig.allowSpecialOptions == true && this.nonBillableRequest != null) {
        this.nonBillableRequest.controls['isInternalRequest'].setValue(true);
      }
    }

    this.refreshSearchResults(null, false);
  }

  public onClearQuickSearch() {
    if (this.quickSearchValue == '') {
      this.isSearchOrClearSearchClicked = true;
      this.refreshSearchResults(null, true);
    }
  }

  public onEnterQuickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue.trim() === '') {
      return;
    }

    this.isSearchOrClearSearchClicked = true;

    var request = <TranscodeMediaSearchRequest>{};

    request.quickSearch = this.quickSearchValue;

    this.refreshSearchResults(request, true);
  }

  public onQuickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue.trim() === '') {
      return;
    }

    this.isSearchOrClearSearchClicked = true;

    var request = <TranscodeMediaSearchRequest>{};

    request.quickSearch = this.quickSearchValue;

    this.refreshSearchResults(request, true);
  }

  public onClientValueChanged(e) {

    if (e.value === this.previousClientId) {
      this.loadClientCreativeButtonDisabled = false;
      return;
    }
    else if (e.value == null) {
      this.loadClientCreativeButtonDisabled = true;
      this.showAssetSearch = false;

      this.brandId = null;
      this.productId = null;

      this.brandDataSource = [];
      this.productDataSource = [];
    }
    else {
      this.loadClientCreativeButtonDisabled = false;
      this.onClientChangeConfirmed();
    }
  }

  public onBrandValueChanged(e) {

    if (e.value === this.previousBrandId || e.value == null)
      return;

    this.onBrandChangeConfirmed();
  }

  public onProductValueChanged(e) {

    if (e.value == null)
      return;
  }

  public onCopyPasteOptionSelected() {
    var initialState = {
      clientId: this.clientId
    };

    this.modalRef = this.modalService.show(TranscodeOrderCopyUploadAssetsPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe((result: SpotFile[]) => {
      console.log(result);
      result.forEach(item => {
        if (this.selectedSpotFiles.includes(item) == false) {
          this.selectedSpotFiles.push(item)
          if (this.mediaForTranscodingGrid.instance != null && (!this.mediaForTranscodingGrid.instance.isRowSelected(item))) {
            this.mediaForTranscodingGrid.instance.selectRows([item.spotFileGuid], true);
          }
        }
      });
    });
  }

  private onClientChangeConfirmed() {

    this.previousClientId = this.clientId;

    this.brandId = null;
    this.productId = null;

    this.brandDataSource = [];
    this.productDataSource = [];

    this.getClientBrands(this.clientId);
  }

  private onBrandChangeConfirmed() {

    this.previousBrandId = this.brandId;

    this.productId = null;

    this.productDataSource = [];

    this.getBrandProducts(this.brandId);
  }

  private getClientBrands(id: number) {

    if (id === null || id <= 0)
      return;

    this.configService.getCurrentClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;

        if (this.brandDataSource.length == 1)
          this.brandId = (this.brandDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private getBrandProducts(id: number) {

    if (id === null || id <= 0)
      return;

    this.configService.getCurrentBrandProducts(id, this.clientId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = (products != null || products != undefined) ? (products.length > 1 ? products.filter(pds => pds.id != 0) : products) : products;

        if (this.productDataSource.length == 1)
          this.productId = (this.productDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  //public onGridSelectionChanged(e) {
  //  e.selectedRowsData.forEach((item, i) => {
  //    if (this.selectedSpotFiles.filter(sd => sd.spotFileGuid == item.spotFileGuid).length == 0)
  //      this.selectedSpotFiles.push(item);
  //  });

  //  e.currentDeselectedRowKeys.forEach((deSelectedItem) => {
  //    this.selectedSpotFiles.forEach((item, j) => {
  //      if (item.spotFileGuid === deSelectedItem)
  //        this.selectedSpotFiles.splice(j, 1);
  //    });
  //  });

  //}

  //public onGridContentReady(e) {
  //  this.selectedSpotFiles.forEach((item, i) => {
  //    if (!e.component.isRowSelected(item))
  //      e.component.selectRows([item.spotFileGuid], true);
  //  });
  //}

  public onGridSelectionChanged(e) {
    e.selectedRowsData.forEach((item, i) => {
      if (this.selectedSpotFiles.filter(sd => sd.spotFileGuid == item.spotFileGuid).length == 0)
        this.selectedSpotFiles.push(item);
    });

    if (this.isSaveSelection == false) {
      e.currentDeselectedRowKeys.forEach((deSelectedItem) => {
        this.selectedSpotFiles.forEach((item, j) => {
          if (item.spotFileGuid === deSelectedItem)
            this.selectedSpotFiles.splice(j, 1);
        });
      });
    }

    this.isSaveSelection = false;
  }

  public onGridContentReady(e) {
    /*  this.isSaveSelection = true;*/
    this.selectedSpotFiles.forEach((item, i) => {
      if (!e.component.isRowSelected(item)) {
        e.component.selectRows([item.spotFileGuid], true);
      }
    });
    if (this.isSearchOrClearSearchClicked) {
      this.isSaveSelection = true;
      this.isSearchOrClearSearchClicked = false;
    }
    else {
      this.isSaveSelection = false;
    }
  }

  public onClearSearch() {
    this.isSearchOrClearSearchClicked = true;
    this.searchRequest.reset();
    this.quickSearchValue = "";
    this.brandDataSource = [];
    this.productDataSource = [];

    if (this.clientDataSource.length == 1) {
      this.searchRequest.controls['client'].setValue(this.clientDataSource[0].id);
      this.clientId = this.clientDataSource[0].id;
      this.onClientChangeConfirmed();
    }
    else {
      this.searchRequest.controls['client'].setValue(this.clientId);
      this.clientId = this.clientId;
      this.onClientChangeConfirmed();
    }

    this.refreshSearchResults(null, true);
  }

  private refreshSearchResults(obj: TranscodeMediaSearchRequest, isRefresh: boolean): void {

    if (obj != null) {
      this.searchCriteriaObj.adID = obj.adID;
      this.searchCriteriaObj.title = obj.title;
      this.searchCriteriaObj.client = this.clientId;
      this.searchCriteriaObj.brand = obj.brand
      this.searchCriteriaObj.product = obj.product;
      this.searchCriteriaObj.format = obj.format;
      this.searchCriteriaObj.mediaType = obj.mediaType;
      this.searchCriteriaObj.quickSearch = obj.quickSearch;
      this.searchCriteriaObj.requestOrigination = this.requestOrigination == 'OrderEdit' ? 'Order' : this.requestOrigination;
      this.searchCriteriaObj.orderGroupSpotFileGuids = this.orderGroupSpotFileGuids;
    }
    else {
      this.searchCriteriaObj.adID = null;
      this.searchCriteriaObj.title = null;
      this.searchCriteriaObj.brand = null;
      this.searchCriteriaObj.product = null;
      this.searchCriteriaObj.format = null;
      this.searchCriteriaObj.mediaType = null;
      this.searchCriteriaObj.quickSearch = null;
      this.searchCriteriaObj.requestOrigination = this.requestOrigination == 'OrderEdit' ? 'Order' : this.requestOrigination;
      this.searchCriteriaObj.orderGroupSpotFileGuids = this.orderGroupSpotFileGuids;
    }

    if (isRefresh) {
      this.mediaForTranscodingGrid.instance.refresh();
    }
    else {
      this.searchMedia();
    }
  }

  public quickPlay(proxy, title, thumbnail, format) {

    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format
    };

    this.modalRef = this.modalService.show(JwPlayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  onFormSubmit({ value, valid }: { value: TranscodeMediaSearchRequest, valid: boolean }) {

    console.log("seleted spots: ", this.selectedSpotFiles);
    value.requestOrigination = this.requestOrigination;
    value.orderGroupSpotFileGuids = this.orderGroupSpotFileGuids;

    if (value.adID === null && value.brand === null && value.client === null && value.product === null && value.format === null && value.mediaType === null && value.title === null) {
      return;
    }

    this.isSearchOrClearSearchClicked = true;

    this.refreshSearchResults(value, true);
  }

  ngOnDestroy() {
  }
}
