<div id="player-pop-up">
    <div class="modal-header" *ngIf="isPopup">
        <button type="button" class="modal-close" (click)="closePopup()">
            <icon-delete color="#000"></icon-delete>
        </button>
        <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="videoSource">
            <div class="col-md-12">
                <div #playerContainer class="jwplayer-container" [ngClass]="playerWidthClass"></div>
                <img *ngIf="thumbnail" [src]="thumbnail" alt="Thumbnail" style="display: none;" />
                <div class="smpte-timecode">{{ smpteTimecode }}</div>
            </div>
        </div>
        <div *ngIf="isOver43Overlay" class="overlay" [ngStyle]="{ height: overyLayHeightQ + 'px' }"></div>
        <div *ngIf="isOver169Overlay" class="overlay" [ngStyle]="{ height: overyLayHeightQ + 'px' }"></div>
    </div>
</div>