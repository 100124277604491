<div class="tylie-accordion accordion" id="draft-order-section">
  <div class="tylie-accordion__panel accordion-item" id="draftOrders">
    <h2 class="tylie-accordion__heading accordion-header" [class.draftOrders-heading]="showAllColumns == true">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#draftOrderCollapseOne" aria-expanded="true" aria-controls="collapseOne">
          DRAFT ORDERS
      </button>
    </h2>
    <div id="draftOrderCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body" id="draftPanelBody">
        <div class="tylie-accordion__body-bar">
          <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onRefresh()"><span class="tylie-button__icon"><icon-refresh></icon-refresh></span><span class="tylie-button__text">REFRESH</span></button>
        </div>
        <div class="tylie-accordion__body-content">
          <dx-data-grid id="draftOrderStoreGrid" class="tylie-grid"
                        #draftOrderStoreGrid
                        [dataSource]="draftOrderStore"
                        [allowColumnReordering]="true"
                        [allowColumnResizing]="true"
                        [columnAutoWidth]="true"
                        [showColumnLines]="true"
                        [showBorders]="true"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        keyExpr="draftOrderGuid"
                        [rowAlternationEnabled]="true"
                        (onRowPrepared)="onRowPrepared($event)">

            <dxo-export [enabled]="false" fileName="Orders" [allowExportSelectedData]="true"></dxo-export>
            <dxi-column dataField="draftOrderNumber" caption="Draft Order #" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
            <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '16%' :'20%'}}"></dxi-column>
            <dxi-column dataField="product" caption="Brand/Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '16%' :'20%'}}"></dxi-column>
            <dxi-column dataField="clientPurchaseOrder" caption="Client Purchase Order" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '14%' :'18%'}}"></dxi-column>
            <dxi-column dataField="jobId" caption="Job #" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '12%' :'16%'}}"></dxi-column>
            <dxi-column dataField="createdBy" caption="Created By" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="{{showAllColumns == true ? '13%' :'0%'}}"></dxi-column>
            <dxi-column dataField="createdDate" cellTemplate="dateTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="desc" width="{{showAllColumns == true ? '10%' :'20%'}}"></dxi-column>
            <dxi-column dataField="draftOrderGuid" cellTemplate="actionTemplate" caption="Actions" [allowSorting]="false" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="16%"></dxi-column>

            <div *dxTemplate="let data of 'actionTemplate'" class="d-flex justify-content-center">

              <button class="tylie-button tylie-button--xxs" (click)="continueToOrder(data.value)">Continue</button>
              <button class="tylie-button tylie-button--xxs ms-2" (click)="copyOrder(data.value)">Copy Order</button>
              <button type="button" class="tylie-button tylie-button--xxs tylie-button--link ms-2" (click)="deleteOrder(data.value)">
                <icon-trash-can color="#1e90ff"></icon-trash-can>
              </button>
            </div>

            <div *dxTemplate="let data of 'dateTemplate'">
              <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
            </div>

            <dxo-remote-operations [sorting]="true"
                                   [paging]="true">
            </dxo-remote-operations>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true"
                       [allowedPageSizes]="[10, 20, 50, 100]"
                       infoText="Page {0} of {1} ({2} items)"
                       [showNavigationButtons]="true"
                       [showInfo]="true">
            </dxo-pager>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>


