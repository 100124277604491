import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Spot } from '../../../models/spot/spot.model';
import { AuthService } from "../../../services/auth/auth.service";
import { DxDataGridComponent } from "devextreme-angular";
import { Utilities } from "../../../services/core/utilities";
import { SpotService } from "../../../services/media/spot.service";
import { AlertService, MessageSeverity } from "../../../services/core/alert.service";
import { Router } from '@angular/router';
import { mediaDetailSource } from "../../../configurations/enums/enums";
import { JwPlayerComponent } from '../../videoplayer/jwplayer/jwplayer.component';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SubService } from "../../../services/media/sub.service";
import { UserProfileService } from "../../../services/user/user-profile.service";
import { UserRoles } from "../../../configurations/user-roles";
import { RouteConstants } from "../../../configurations/route-constants";
import { SpotSearchRequest } from '../../../models/spot/spot-search-request.model';
import { GlobalService } from '../../../services/core/global.service';
import { environment } from '../../../../environments/environment';
import { Constants } from '../../../configurations/constants';
import { AddToPlayListComponent } from '../../popups/addtoplaylist/addtoplaylist.component';
import { PlayListFileNew } from '../../../models/playlist/playlist-file-new.model';

@Component({
  selector: 'spot-approval',
  templateUrl: './spotapproval.component.html',
  styleUrls: ['./spotapproval.component.css'],
})
export class SpotApprovalComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGridApproval: DxDataGridComponent;
  @Input('spotapprovalList') spotapproval: Spot[];

  public approvalSpots: Spot[] = [];
  public quickApprovalSearchValue: string = '';
  public modalRef: BsModalRef;
  public sub;
  private refreshId: any;

  constructor(
    private util: Utilities,
    private router: Router,
    private modalService: BsModalService,
    private spotService: SpotService,
    private alertService: AlertService,
    private subService: SubService,
    private userProfileService: UserProfileService,
    private userRoles: UserRoles, private gs: GlobalService) {

    this.subService.refreshPlayListMediaApprovalMethodCalled.subscribe(
      () => {
        this.deSelectRows();
      }
    );

    this.sub = this.subService.refreshApprovalSpotsBacktoMediaMethodCalled.subscribe(
      () => {
        if (this.userProfileService.getUserRoles().includes(this.userRoles.getMediaApproverRoleName())) {
          return this.refreshData();
        }
      }
    );

    this.quickApprovalSearchValue = '';
  }

  ngOnInit() {

    for (let s of this.spotapproval) {
      s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), false, false);
      s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);
    }

    this.approvalSpots = this.spotapproval;

    if (this.userProfileService.getUserRoles().includes(this.userRoles.getMediaApproverRoleName())) {
      this.refreshId = setInterval(() => {
        this.refreshData();
      }, environment.mediaApprovalGridRefreshTimeInMins * 60000);
    }
  }

  // search on enter click
  public onEnterQuickSearch(e: any) {
    this.searchApprovalSpots();
  }

  public refreshData() {
    if (this.quickApprovalSearchValue === null || this.quickApprovalSearchValue.trim() === '')
      this.getApprovalSearchSpots();
    else
      this.searchApprovalSpots();
  }

  public onRefresh() {
    this.refreshData();
  }

  public searchApprovalSpots() {

    if (this.quickApprovalSearchValue === null || this.quickApprovalSearchValue.trim() === '') {
      this.alertService.logInfo("No Search Criteria");
      return;
    }

    var searchCriteria = <SpotSearchRequest>{};
    searchCriteria.quickSearch = this.quickApprovalSearchValue.trim();

    let request: any = { SearchCriteria: searchCriteria, GridParams: null, isSpotSearch: false };

    this.spotService.searchApprovalReadySpots(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var spots = res.result as Spot[];

        for (let s of spots) {
          s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), false, false);

          s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);
        }

        this.approvalSpots = spots as Spot[];

        //this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        //this.alertService.ShowLoader(false);
        this.approvalSpots = [];
      });
  }

  public getApprovalSearchSpots() {

    //this.alertService.ShowLoader(true);

    this.spotService.getApproavalReadySpots().subscribe((res: any) => {
      if (res.isSuccess == true) {
        var spots = res.result as Spot[];

        for (let s of spots) {
          s.thumbnailUrl = this.util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), false, false);

          s.proxyUrl = this.util.getProxyUrl(s.proxyUrl);
        }

        this.approvalSpots = spots as Spot[];
        this.util.spotApprovalCount = spots.length
        //this.alertService.ShowLoader(false);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        //this.alertService.ShowLoader(false);
        this.approvalSpots = [];
      });
  }

  public clearQuickSearch() {
    if (this.quickApprovalSearchValue == '') {
      this.getApprovalSearchSpots();
    }
  }

  public showDetail(id) {
    if (id == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openSpotFile(id);

    //this.router.navigate([RouteConstants.mediaDetailRoute, id], { queryParams: { source: mediaDetailSource[mediaDetailSource.Approval] } });
  }

  public quickPlay(proxy, title, thumbnail, format) {
    if (proxy == null || proxy == '')
      return;

    var initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format
    };

    this.modalRef = this.modalService.show(JwPlayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public showChoosePlayListPopup() {

    if (this.dataGridApproval.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }

    if (this.dataGridApproval.instance.getSelectedRowKeys().length > 0 && this.checkIfSelectedSpotsGotVideoFiles() === false) {
      this.alertService.showMessage("ERROR", Constants.noVideoFilesForSelectedSpots, MessageSeverity.error);
      return;
    }

    var initialState = {
      selectedPlayListFiles: this.getSelectedSpotsAsPlayListFiles(),
      isPopup: true,
      source: "approval"
    };

    this.modalRef = this.modalService.show(AddToPlayListComponent, this.util.getModalComponentOptions(initialState, false, true, false));
  }

  private checkIfSelectedSpotsGotVideoFiles(): boolean {
    var grid = this.dataGridApproval.instance;
    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      if (grid.getSelectedRowsData()[i].isPlayable == false)
        return false;
    }
    return true;
  }

  private getSelectedSpotsAsPlayListFiles(): PlayListFileNew[] {
    let selectedSpotFiles: string[] = [];
    let playListFiles: PlayListFileNew[] = [];
    var grid = this.dataGridApproval.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedSpotFiles.push(grid.getSelectedRowsData()[i].spotFileGuid);
    }

    for (var i = 1; i <= selectedSpotFiles.length; i++)
      playListFiles.push({ sequence: i, spotFileGuid: selectedSpotFiles[i - 1] });

    return playListFiles;
  }

  private deSelectRows() {
    this.dataGridApproval.instance.clearSelection();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();

    if (this.refreshId)
      clearInterval(this.refreshId);
  }
}
