import { Component, Inject, ViewChild, Input, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DxTreeViewComponent, DxDataGridComponent } from 'devextreme-angular';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Utilities } from "../../../services/core/utilities";
import { Constants } from "../../../configurations/constants";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { SubService } from "../../../services/media/sub.service";
import { UserRoles } from "../../../configurations/user-roles";
import 'devextreme/integration/jquery';
import { ConfigService } from "../../../services/core/config.service";
import { RouteConstants } from "../../../configurations/route-constants";
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from '../../../models/config/gridparams.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Observable } from 'rxjs';
import { GlobalService } from '../../../services/core/global.service';
import { DownloadMediaService } from '../../../services/media/media-download.service';
import { SpotFile } from '../../../models/spot/spot-file.model';
import { ErrorPopupComponent } from '../../popups/errorpopup/errorpopup.component';
import { DistributionSearchRequest, MediaFileData } from '../../../models/distribution/distribution-search-request..model';
import { DistributionService } from '../../../services/distribution/distribution.service';
import { DownloadMediaFileStatus } from '../../../models/download/downloadmedia-status';
import { UpdateDistroMediaRequest } from '../../../models/distribution/update-distro-media-request.model';
import { UpdateDistributionMediaPopUp } from '../../popups/updatedistributionmediapopup/updatedistributionmediapopup.component';
import { JwPlayerComponent } from '../../videoplayer/jwplayer/jwplayer.component';

@Component({
  selector: 'distro-media-search',
  templateUrl: './distributionsearch.component.html',
  styleUrls: ['./distributionsearch.component.css'],
})

export class DistributionSearchComponent implements OnInit {

  public clientDataSource: any;
  public brandDataSource: any;
  public stationDataSource: any;
  public formatDataSource: any;
  public productDataSource: any;
  public mediatypeDataSource: any;
  public searchConfig: any = null;

  @ViewChild('distributionSearch', { static: true }) gridDistroSearch: DxDataGridComponent;

  public brandValue: number[] = [];
  public searchRequest: FormGroup;
  public quickSearchValue: string = '';
  public now: Date = new Date();
  public sub;

  public selectedMediaFiles: Array<MediaFileData>;

  public modalRef: BsModalRef;

  public resultSpotsStore: any = {};
  public searchCriteriaObj: DistributionSearchRequest = <DistributionSearchRequest>{};
  public scroll: boolean = false;

  public dataGridColumnsAdded: string[] = [];

  detailGridInstances: Array<any>;

  @ViewChildren('delDownloadpop') delDownloadpop: QueryList<PopoverDirective>;

  constructor(
    public alertService: AlertService,
    private router: Router,
    public userRoles: UserRoles,
    private distributionService: DistributionService,
    private modalService: BsModalService,
    public util: Utilities,
    private subService: SubService,
    private ds: DownloadMediaService,
    public configService: ConfigService, private gs: GlobalService) {

    this.selectedMediaFiles = [];
    this.detailGridInstances = [];

  }

  ngAfterViewInit() {

  }

  ngOnInit() {

    this.searchRequest = new FormGroup({
      deliveryDate: new FormControl(null),
      downloadDate: new FormControl(null),
      destination: new FormControl(null),
      brandId: new FormControl(null),
      clientId: new FormControl(null),
      productId: new FormControl(null),
      mediaTypeId: new FormControl(null),
      formatTypeId: new FormControl(null)
    });

    this.getConfig();
    this.searchDistributionMedia();
  }

  private getConfig() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.configService.getDistributionMediaAdvancedSearchConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.searchConfig = res.result;

        this.clientDataSource = this.searchConfig.clients;
        this.brandDataSource = this.searchConfig.brands;
        this.formatDataSource = this.searchConfig.formats;
        this.mediatypeDataSource = this.searchConfig.mediaTypes;
        this.productDataSource = this.searchConfig.products;
        this.stationDataSource = this.searchConfig.stations;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  // search on enter click
  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue.trim() === '') {
      return;
    }

    var request = <DistributionSearchRequest>{};
    request.quickSearch = this.quickSearchValue;
    this.refreshSearchResults(request, false, true);
  }

  clearQuickSearch() {
    if (this.quickSearchValue == '') {
      this.refreshSearchResults(null, false, true);
    }
  }

  clearSearch() {
    this.searchRequest.reset();
    this.quickSearchValue = "";
   // this.brandDataSource = [];

    this.selectedMediaFiles = [];

    this.getBrandProducts(0);

    this.refreshSearchResults(null, false, true);
  }

  onFormSubmit({ value, valid }: { value: DistributionSearchRequest, valid: boolean }) {
    value.destination = value.destination == null ? this.searchConfig.userDestination : value.destination;
    if (value.brandId === null && value.clientId === null && value.productId === null && value.deliveryDate === null
      && value.downloadDate === null && value.mediaTypeId === null && value.formatTypeId === null && value.destination === null) {
      return;
    }

    if (value.deliveryDate != null)
      value.deliveryDate = moment(value.deliveryDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

    if (value.downloadDate != null)
      value.downloadDate = moment(value.downloadDate).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).format();

    this.refreshSearchResults(value, false, true);
  }

  private refreshSearchResults(obj: DistributionSearchRequest, scroll: boolean, isRefresh: boolean): void {
    console.log(obj);
    if (obj != null) {
      this.searchCriteriaObj.destination = obj.destination;
      this.searchCriteriaObj.brandId = obj.brandId;
      this.searchCriteriaObj.clientId = obj.clientId;
      this.searchCriteriaObj.productId = obj.productId;
      this.searchCriteriaObj.deliveryDate = obj.deliveryDate;
      this.searchCriteriaObj.downloadDate = obj.downloadDate;
      this.searchCriteriaObj.formatTypeId = obj.formatTypeId;
      this.searchCriteriaObj.mediaTypeId = obj.mediaTypeId;
      this.searchCriteriaObj.quickSearch = obj.quickSearch;
    }
    else {
      this.searchCriteriaObj.destination = null;
      this.searchCriteriaObj.brandId = null;
      this.searchCriteriaObj.clientId = null;
      this.searchCriteriaObj.productId = null;
      this.searchCriteriaObj.deliveryDate = null;
      this.searchCriteriaObj.downloadDate = null;
      this.searchCriteriaObj.formatTypeId = null;
      this.searchCriteriaObj.mediaTypeId = null;
      this.searchCriteriaObj.quickSearch = null;

      this.searchRequest.controls['brandId'].setValue(null);
      this.searchRequest.controls['clientId'].setValue(null);
    }

    this.scroll = scroll;

    if (isRefresh) {
      this.gridDistroSearch.instance.refresh();
    }
    else {
      this.searchDistributionMedia();
    }

    this.selectedMediaFiles = [];
  }

  getClientBrands(id: number) {
    this.configService.getClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  onClientValueChanged(e) {
    this.getClientBrands(e.value);
    this.getClientProducts(e.value);
  }

  public getClientProducts(id: number) {
    this.productDataSource = [];
    this.searchRequest.controls['productId'].setValue("");

    this.configService.getClientProducts(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.productDataSource = [];
      });
  }


  clearProductSelection(e) {
    if (e == null || e == undefined || e.value == null) {
      this.getBrandProducts(0);
    }
    else {
      this.getBrandProducts(e.value);
    }
  }

  getBrandProducts(id: number) {
    this.productDataSource = [];
    this.searchRequest.controls['productId'].setValue("");
    this.configService.getBrandProducts(id, this.searchRequest.value.client).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;
        this.productDataSource = this.productDataSource.filter(pds => pds.id != 0);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.productDataSource = [];
      });
  }

  searchDistributionMedia() {
    var distributionService = this.distributionService;
    var util = this.util;
    var criteriaObj = this.searchCriteriaObj;
    var scroll = this.scroll;

    this.resultSpotsStore = new CustomStore({
      key: "mediaId",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'deliveryDate','desc':true}]";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false }
        let request: any = { Criteria: criteriaObj, GridParams: gridParams };

        return distributionService.searchDistributionMedia(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var distributionSearchResponse = response.result;
              var spots = distributionSearchResponse.media;  // create obj

              for (let s of spots) {
                s.thumbnailUrl = util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), false, false);
                s.proxyUrl = util.getProxyUrl(s.proxyUrl);
                s.formatSources = util.getAllIconSources(s.format, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
              }

              var obj: any = {
                data: spots,
                totalCount: distributionSearchResponse.totalCount
              };

              if (scroll)
                util.scrollIfNeededTo();

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }


  onSpotFilesGridSelectionChanged(e) {
    e.selectedRowsData.forEach((item, i) => {
      if (this.selectedMediaFiles.filter(sd => sd.mediaFileId == item.mediaFileId).length == 0)
        this.selectedMediaFiles.push(item);
    });

    e.currentDeselectedRowKeys.forEach((deSelectedItem) => {
      this.selectedMediaFiles.forEach((item, j) => {
        if (item.mediaFileId === deSelectedItem)
          this.selectedMediaFiles.splice(j, 1);
      });
    });
  }

  onSpotFilesGridContentReady(e) {
    this.detailGridInstances.push(e.component);
  }

  onCellPrepared(e: any) {

    if (e.rowType === "data" && e.column.command === "expand") {

      if (e.data != null) {
        if (e.data.files.length == 0) {
          e.cellElement.removeClass("dx-datagrid-expand");
          e.cellElement.empty();
        }
      }
    }
  }

  public deSelectRows() {
    this.gridDistroSearch.instance.clearSelection();

    this.detailGridInstances.forEach((g) => {
      g.deselectRows(g.getSelectedRowKeys());
    });
  }

  public downloadSpots() {
    if (this.selectedMediaFiles.length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }
    else {

      var invalidSpots = this.getSpotsThanCannotBeDownloaded();

      if (invalidSpots.length > 0) {

        var initialState = {
          errors: invalidSpots,
          title: 'The following asset are not available to download'
        };

        this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

        return;
      }
    }

    var response = this.ds.initializeDistributionDownload(this.getSelectedDownloadableSpots());

    if (response == true)
      this.router.navigate([RouteConstants.distributionDownloadRoute]);  // add distribution download route
  }

  public onDownloadBxfFile(mediaFileId, fileName, destination) {
    var destination = destination;
    this.distributionService.getBxfFileDownloadUrl({ mediaFileId: mediaFileId, Destination: destination }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var result = res.result;
        this.downloadFile(result, fileName);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public onAddEditDistroMedia(data) {

    var initialState = {
      houseId: data.houseId
    };

    this.modalRef = this.modalService.show(UpdateDistributionMediaPopUp, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onClose.subscribe((result: UpdateDistroMediaRequest) => {
      if (result != null && result != undefined) {
        this.distributionService.updateDistributionMedia(data.mediaId, result).subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.refreshSearchResults(null, false, true);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
      }
    });
  }

  public quickPlay(proxy, title, thumbnail, format) {

    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format
    };

    this.modalRef = this.modalService.show(JwPlayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onRepitchDistroMedia() {
    if (this.gridDistroSearch.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }

    this.distributionService.repitchDistributionMedia({ mediaIds: this.gridDistroSearch.instance.getSelectedRowKeys() }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.refreshSearchResults(null, false, true);
        this.deSelectRows();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private downloadFile(path, fileName) {
    this.util.downloadFile(path).subscribe((event: any) => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = window.URL.createObjectURL(event);
      a.download = fileName;
      a.click();
    },
      error => {
        console.log(error);
        this.util.handleIsNotSuccess(['Error downloading the file']);
      });
  }

  private getSelectedDownloadableSpots(): Array<MediaFileData> {
    let selectedSpotFiles: MediaFileData[] = [];
    console.log(this.selectedMediaFiles);
    for (var i = 0; i < this.selectedMediaFiles.length; i++) {
      let sf = {} as MediaFileData;
      sf.downloadId = this.selectedMediaFiles[i].downloadId;
      sf.adId = this.selectedMediaFiles[i].adId;
      sf.title = this.selectedMediaFiles[i].title;
      sf.outputFileName = this.selectedMediaFiles[i].outputFileName;
      sf.spec = this.selectedMediaFiles[i].spec;

      selectedSpotFiles.push(sf);
    }
    console.log(selectedSpotFiles);
    return selectedSpotFiles;
  }

  private getSpotsThanCannotBeDownloaded(): Array<string> {

    var spotsThatCannotBeDownloaded: Array<string> = [];

    for (var i = 0; i < this.selectedMediaFiles.length; i++) {
      if (this.selectedMediaFiles[i].isAvailableForDownload == false)
        spotsThatCannotBeDownloaded.push(this.selectedMediaFiles[i].outputFileName + " - " + this.selectedMediaFiles[i].spec + " : " + "Please add House ID.");
    }

    return spotsThatCannotBeDownloaded;
  }

  ngOnDestroy() {


  }
}
