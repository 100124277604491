import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Params, ActivatedRoute, Router } from "@angular/router";
import { DxDataGridComponent } from 'devextreme-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Constants } from '../../configurations/constants';
import { RouteConstants } from '../../configurations/route-constants';
import { UserRoles } from '../../configurations/user-roles';
import { EditTranscodeOrderRequest, PagerSetting, TranscodeAssetFile, TranscodeOrder, TranscodeOrderSearchResult } from '../../models/order/transcodeorder.model';
import { AlertService, MessageSeverity } from '../../services/core/alert.service';
import { Utilities } from '../../services/core/utilities';
import { DownloadMediaService } from '../../services/media/media-download.service';
import { OrderService } from '../../services/order/order.service';
import { OrderDetailHeaderEditPopupComponent } from '../popups/orderdetailheadereditpopup/orderdetailheadereditpopup.component';
import { ShareTranscodeFilePopupComponent } from '../popups/sharetranscodefilepopup/sharetranscodefilepopup.component';
import { JwPlayerComponent } from '../videoplayer/jwplayer/jwplayer.component';

@Component({
  selector: 'transcodeassets',
  templateUrl: './transcodeassets.component.html',
  styleUrls: ['./transcodeassets.component.css']
})
export class TranscodeAssetsComponent implements OnInit {

  public ordersSource: Array<TranscodeOrder> = [];
  public modalRef: BsModalRef;
  public quickSearchValue: string = "";
  public pager: PagerSetting = null;
  public NA: string = "N/A";
  public NOT_YET: string = "Not Yet";

  private defaultPageNumber: number = 1;
  private defaultPageSize: number = 5;

  @ViewChildren('assetFileGrid') assetFileGrids: QueryList<DxDataGridComponent>;
  @ViewChildren('deletefailedpop') deletefailedpop: QueryList<PopoverDirective>;
  @ViewChildren('displErrorpop') displErrorpop: QueryList<PopoverDirective>;

  constructor(public util: Utilities,
    private route: ActivatedRoute,
    private router: Router,
    public userRoles: UserRoles,
    public bsModalRef: BsModalRef,
    public alertService: AlertService,
    private modalService: BsModalService,
    private orderService: OrderService,
    private ds: DownloadMediaService,
    ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document) {
    window.onscroll = (e) => {
      ngZone.run(() => {
        if (this.document.documentElement.scrollTop > 50) {
          $('.scrolltop:hidden').stop(true, true).fadeIn();
        } else {
          $('.scrolltop').stop(true, true).fadeOut();
        }
      });
    };
  }

  ngOnInit() {
    this.initPager();

    this.getOrders(this.defaultPageSize, this.defaultPageNumber);
  }

  private initPager() {
    this.pager = new PagerSetting();

    this.pager.pageSizes = [5, 10, 15, 20];

    this.pager.pageNumbers = [];
  }

  public setPageSize(newPageSize: number) {
    this.getOrders(newPageSize, this.defaultPageNumber);
  }

  public setPageNumber(newPageNumber: number) {

    if (newPageNumber < 1 || newPageNumber > this.pager.totalPages)
      return;

    this.getOrders(this.pager.currentPageSize, newPageNumber);
  }

  private setPager(totalCount: number, pageSize: number, pageNumber: number) {
    this.pager.totalCount = totalCount;
    this.pager.totalPages = Math.ceil(totalCount / pageSize);
    this.pager.currentPageSize = pageSize;
    this.pager.currentPageNumber = pageNumber;

    this.pager.pageNumbers = [];

    let startPage: number, endPage: number;
    let totalPages: number = this.pager.totalPages;
    let currentPage: number = this.pager.currentPageNumber;
    let totalItems: number = this.pager.totalCount;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        if ((totalPages - (currentPage - 2)) == 5) {
          startPage = currentPage - 1;
          endPage = currentPage + 3;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
      }
    }

    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    for (let i = startPage; i <= endPage; i++)
      this.pager.pageNumbers.push(i);

    this.pager.startPage = startPage;

    this.pager.endPage = endPage;
  }

  private getOrders(pageSize: number, pageNumber: number) {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    let request = { quickSearch: this.quickSearchValue, pageSize: pageSize, pageNumber: pageNumber };

    this.orderService.searchTranscodeOrders(request).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var ordersResult = res.result as TranscodeOrderSearchResult;

        this.ordersSource = ordersResult.transcodeOrders;

        this.ordersSource.forEach(order => {
          order.transcodeAssets.forEach(asset => {
            asset.spotFile.thumbnailUrl = this.util.getThumbnailUrl(asset.spotFile.thumbnailUrl, (asset.spotFile.format.toLowerCase() == 'audio'), (asset.spotFile.status.toLowerCase() == 'awaiting media'), (asset.spotFile.status.toLowerCase() == 'creating media'));
            asset.spotFile.proxyUrl = this.util.getProxyUrl(asset.spotFile.proxyUrl);
          });
        });

        this.setPager(ordersResult.totalCount, pageSize, pageNumber);

        console.log(this.ordersSource);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.alertService.ShowLoader(false);
        this.util.handleError(error);
      });
  }

  public onQuickSearch() {
    this.getOrders(this.pager.currentPageSize, this.defaultPageNumber);
  }

  public onClearQuickSearch() {
    if (this.quickSearchValue == '')
      this.getOrders(this.pager.currentPageSize, this.defaultPageNumber);
  }

  public onGotoMedia(section) {
    this.router.navigate([RouteConstants.mediaRoute], { queryParams: { t: section } });
  }

  public onGotoUpload() {
    this.router.navigate([RouteConstants.mediaUploadRoute]);
  }

  public onGotoDownload() {
    this.router.navigate([RouteConstants.mediaDownloadRoute], { queryParams: { t: "transcode" } });
  }

  public onGotoOrderTranscode() {
    this.router.navigate([RouteConstants.orderTrancodesRoute]);
  }

  public onGotoTranscodeSavedLists() {
    this.router.navigate([RouteConstants.trancodeOrderListsSaved]);
  }

  public onGotoTranscodeOLVLists() {
    this.router.navigate([RouteConstants.trancodeOrderListsOLV]);
  }

  public gotoTalentRights() {
    this.router.navigate([RouteConstants.talentRightsRoute]);
  }

  public quickPlay(proxy, title, thumbnail, format) {

    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format
    };

    this.modalRef = this.modalService.show(JwPlayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onEditRefCode(workOrder, orderGuid, refCode) {
    var initialState = {
      transcodeOrderGuid: orderGuid,
      transcodeOrderWorkOrder: workOrder,
      currentTranscodeValue: refCode,
      editCategory: "REFERENCECODE",
      isTranscodeOrder: true
    };

    this.modalRef = this.modalService.show(OrderDetailHeaderEditPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onTranscodeOrderEditComplete.subscribe(result => {
      var orderIndex = this.ordersSource.findIndex(t => t.orderGuid == orderGuid);

      if (orderIndex >= 0)
        this.ordersSource[orderIndex].referenceCode = result.newValue;
    });
  }

  public onEditFileName(workOrder, orderGuid, assetGuid, assetFileGuid, fileName) {

    var initialState = {
      transcodeOrderGuid: orderGuid,
      transcodeOrderAssetFileGuid: assetFileGuid,
      transcodeOrderWorkOrder: workOrder,
      currentTranscodeValue: fileName,
      editCategory: "FILENAME",
      isTranscodeOrder: true
    };

    this.modalRef = this.modalService.show(OrderDetailHeaderEditPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onTranscodeOrderEditComplete.subscribe(result => {
      var orderIndex = this.ordersSource.findIndex(t => t.orderGuid == orderGuid);

      if (orderIndex >= 0) {
        var assetIndex = this.ordersSource[orderIndex].transcodeAssets.findIndex(t => t.assetGuid == assetGuid);

        if (assetIndex >= 0) {
          var assetFileIndex = this.ordersSource[orderIndex].transcodeAssets[assetIndex].transcodeAssetFiles.findIndex(t => t.assetFileGuid == assetFileGuid)

          if (assetFileIndex >= 0)
            this.ordersSource[orderIndex].transcodeAssets[assetIndex].transcodeAssetFiles[assetFileIndex].fileName = result.newValue;
        }
      }
    });
  }

  public onShare(assetFileGuid, fileName, fileNameWithExt, filePath, transcodeSpec) {

    var initialState = {
      assetFiles: this.getSelectedSharableTranscodeAssetFiles(assetFileGuid, fileName, fileNameWithExt, filePath, transcodeSpec)
    };

    this.modalRef = this.modalService.show(ShareTranscodeFilePopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "shareTranscodePopup"));
  }

  private getSelectedSharableTranscodeAssetFiles(assetFileGuid: string, fileName: string, fileExtension: string, filePath: string, transcodeSpec: string): Array<TranscodeAssetFile> {
    let assetFiles: TranscodeAssetFile[] = [];

    let af = new TranscodeAssetFile();
    af.assetFileGuid = assetFileGuid;
    af.filePath = filePath;
    af.fileName = fileName;
    af.fileExtension = fileExtension;
    af.transcodeSpec = transcodeSpec

    assetFiles.push(af);
debugger
    return assetFiles;
  }

  public onDownload(assetFileGuid, fileName, fileNameWithExt, filePath) {

    var response = this.ds.initiateS3TranscodeFileDownload(this.getSelectedDownloadableTranscodeAssetFiles(assetFileGuid, fileName, fileNameWithExt, filePath));

    if (response == true)
      this.onGotoDownload();
  }

  private getSelectedDownloadableTranscodeAssetFiles(assetFileGuid: string, fileName: string, fileExtension: string, filePath: string): Array<TranscodeAssetFile> {
    let assetFiles: TranscodeAssetFile[] = [];

    let af = new TranscodeAssetFile();
    af.assetFileGuid = assetFileGuid;
    af.filePath = filePath;
    af.fileName = fileName;
    af.fileExtension = fileExtension;

    assetFiles.push(af);

    return assetFiles;
  }

  private getBatchSelectedDownloadableTranscodeAssetFiles(): Array<TranscodeAssetFile> {
    let selectedTranscodeAssetFiles: TranscodeAssetFile[] = [];

    this.assetFileGrids.forEach(p => {
      var instance = p.instance;

      if (instance.getSelectedRowKeys().length > 0) {
        for (var i = 0; i < instance.getSelectedRowKeys().length; i++) {

          if (!instance.getSelectedRowsData()[i].canDownload)
            continue;

          let af = new TranscodeAssetFile();
          af.assetFileGuid = instance.getSelectedRowsData()[i].assetFileGuid;
          af.filePath = instance.getSelectedRowsData()[i].filePath;
          af.fileName = instance.getSelectedRowsData()[i].fileName;
          af.fileExtension = instance.getSelectedRowsData()[i].fileExtension;

          selectedTranscodeAssetFiles.push(af);
        }
      }
    });

    return selectedTranscodeAssetFiles;
  }

  private getBatchSelectedSharableTranscodeAssetFiles(): Array<TranscodeAssetFile> {
    let selectedTranscodeAssetFiles: TranscodeAssetFile[] = [];

    this.assetFileGrids.forEach(p => {
      var instance = p.instance;

      if (instance.getSelectedRowKeys().length > 0) {
        for (var i = 0; i < instance.getSelectedRowKeys().length; i++) {

          if (!instance.getSelectedRowsData()[i].canDownload)
            continue;

          let af = new TranscodeAssetFile();
          af.assetFileGuid = instance.getSelectedRowsData()[i].assetFileGuid;
          af.filePath = instance.getSelectedRowsData()[i].filePath;
          af.fileName = instance.getSelectedRowsData()[i].fileName;
          af.fileExtension = instance.getSelectedRowsData()[i].fileExtension;
          af.transcodeSpec = instance.getSelectedRowsData()[i].transcodeSpec;

          selectedTranscodeAssetFiles.push(af);
        }
      }
    });

    return selectedTranscodeAssetFiles;
  }

  public onDeleteFailed(workOrder, orderGuid, assetGuid, assetFileGuid) {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    let request: EditTranscodeOrderRequest = { assetFileGuid: assetFileGuid, editCategory: "DELETEASSETFILE", newValue: "", orderGuid: orderGuid, workOrder: workOrder };

    this.orderService.editTranscodeOrder(request).subscribe((res: any) => {
      if (res.isSuccess == true) {

        var orderIndex = this.ordersSource.findIndex(t => t.orderGuid == orderGuid);

        if (orderIndex >= 0) {
          var assetIndex = this.ordersSource[orderIndex].transcodeAssets.findIndex(t => t.assetGuid == assetGuid);

          if (assetIndex >= 0) {
            var assetFileIndex = this.ordersSource[orderIndex].transcodeAssets[assetIndex].transcodeAssetFiles.findIndex(t => t.assetFileGuid == assetFileGuid)

            if (assetFileIndex >= 0)
              this.ordersSource[orderIndex].transcodeAssets[assetIndex].transcodeAssetFiles = this.ordersSource[orderIndex].transcodeAssets[assetIndex].transcodeAssetFiles.filter(t => t.assetFileGuid != assetFileGuid);
          }
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.onHideDeleteFailedPop();
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.onHideDeleteFailedPop();
      });
  }

  public onGetDeleteFileData(workOrder, orderGuid, assetGuid, assetFileGuid) {
    return { workOrder: workOrder, orderGuid: orderGuid, assetGuid: assetGuid, assetFileGuid: assetFileGuid };
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data" && e.data.isFailed)
      e.rowElement.addClass('errortext');
  }

  public onCellPrepared(e: any) {
    if (e.rowType === "data" && e.column.dataField === "createdDate" && !e.data.isCompleted)
      e.cellElement.addClass('errortext');

    if (e.rowType === "data" && e.data.isFailed)
      e.cellElement.addClass('errortext');
  }

  public onBatchDownload() {

    let assetFiles = this.getBatchSelectedDownloadableTranscodeAssetFiles();

    if (assetFiles.length > 0) {
      var response = this.ds.initiateS3TranscodeFileDownload(assetFiles);

      if (response == true)
        this.onGotoDownload();
    }
    else
      this.alertService.showMessage("ERROR", Constants.transcode_selectfiles_download, MessageSeverity.error);
  }

  public onBatchShare() {
    let assetFiles = this.getBatchSelectedSharableTranscodeAssetFiles();

    if (assetFiles.length > 0) {
      var initialState = {
        assetFiles: assetFiles
      };

      this.modalRef = this.modalService.show(ShareTranscodeFilePopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "shareTranscodePopup"));
    }
    else
      this.alertService.showMessage("ERROR", Constants.transcode_selectfiles_share, MessageSeverity.error);
  }

  public onHideDeleteFailedPop() {
    this.deletefailedpop.forEach(p => {
      p.hide();
    });
  }

  public onHideDisplayErrorPop() {
    this.displErrorpop.forEach(p => {
      p.hide();
    });
  }

  public onScrollToTop() {
    this.util.scrollTo('scrollContainer');
  }
}

//isFailed: row color to errortext
//!isCompleted: created column cell color to errortext
//download: when canDownload and add download icon
//share: when canShare and add share icon
//rename: when canRenameFileName and add rename icon
//delete: when canDelete and add delete icon
